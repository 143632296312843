<template>
  <div>Search..</div>
</template>

<script>
/**
 * @TODO this page does nothing at this moment, but perhaps we'll need more pages one for every account type
 */
export default {
  name: 'Search',
};
</script>

<style scoped>

</style>
